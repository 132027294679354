import firebase from '.';

export class FirebaseFunctionUtils {

    public static async executeHttpFunc(funcName: string, req: any): Promise<any> {
        // https://firebase.google.com/docs/functions/http-events
        // https://cloud.google.com/functions/docs/writing/http
        const httpFunc = firebase.functions().httpsCallable(funcName);
        await httpFunc(req);
    }
}