import { Component, OnInit } from '@angular/core';
import { TransformImage } from '../../../components/image-transform/image.transform';

@Component({
  selector: 'zs-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {

  public techImage: TransformImage[] = [
    // new TransformImage("color-adjust/508248420color.jpg", "rotate-x")
    new TransformImage("175015817.jpg", "move-diagonal"), // beautiful blue code building
  ];

  constructor() { }

  ngOnInit() {
  }

}
