import { Component, OnInit, ViewEncapsulation, HostListener, NgModule, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ReactiveService } from '../../services/reactive.service';
import { ImageTransformService } from '../../components/image-transform/image.transform.service';
import * as AOS from 'AOS';

@Component({
  selector: 'zs-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  isMobileDevice: boolean;
  isVhSupported: boolean;
  imagesLoading: boolean = true;
  latestKnownScrollY: number = 0;
  ticking: boolean = false;
  private fragment: string;
  // scrollEvent:string = "scroll";
  // isScrollingTo:boolean;

  // any insted of Document for the compiler for production mode
  constructor(@Inject(DOCUMENT) private document: any, private route: ActivatedRoute,
    public reactiveService: ReactiveService, private imageTransformService: ImageTransformService) { }

  ngOnInit() {
    this.reactiveService.init();
    this.isMobileDevice = this.reactiveService.isMobile();
    this.isVhSupported = this.reactiveService.cssPropertyValueSupported('height', '1vh');
    this.imageTransformService.imagesLoaded().then(() => {
      this.imagesLoading = false;
    });

    AOS.init({
      startEvent: 'load',
      offset: 60,
      disable: !this.reactiveService.isModernBrowser()
      //duration: 400
    });

    window.addEventListener('load', AOS.refresh);
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });

    //Skype.ui({
    //  "name": "dropdown",
    //  "element": "SkypeButton_Call_zedworks_1",
    //  "participants": ["live:zedworks_3"],
    //  "imageColor": "white",
    //  "imageSize": 24
    //});
  }

  ngAfterViewInit(): void {
    if (!this.fragment) return;
    try {
      const el = document.querySelector('#' + this.fragment);
      if (el && el.scrollIntoView) {
        el.scrollIntoView();
      } else {
        window.location.href = window.location.origin + `#${this.fragment}`;
      }
    } catch (e) { }
  }

  @HostListener("window:resize", [])
  onWindowResize() {
    this.reactiveService.onResize.emit();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (this.isMobileDevice || !this.reactiveService.isModernBrowser()) return;

    let number = this.document.body.scrollTop || this.document.documentElement.scrollTop;
    this.latestKnownScrollY = number;

    // console.log(number);
    //if (number == 0){
    //  this.reactiveService.homePageScroll.emit(true);
    //} else {
    //  this.reactiveService.homePageScroll.emit(false);
    //}

    //this.reactiveService.scrolled((number > window.innerHeight - 130)? number: 0);
    this.reactiveService.scrolled((number > 50) ? number : 0);
    //requestAnimationFrame(this.reactiveService.scrolled((number > 50)? number: 0));
  }


  //handleButtonFocus(){
  //  let btns = this.document.getElementsByTagName('button');
  //  for (let i = 0; i < btns.length; i++){
  //    btns[i].addEventListener("click", blur.bind(this));
  //  }
  //
  //  function blur(a){
  //    console.log(document.activeElement);
  //     // document.activeElement.blur();
  //    // a.currentTarget.blur();
  //    // this.document.body.click();
  //    return false;
  //  }
  //}


}
