import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Response, Headers } from "@angular/http";
// import { Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { FirebaseFunctionUtils } from '../../firebase/FirebaseFunctionUtils';

export enum EmailType {
  CONTACT = "ZEDWORKS_SOFTWARE_NEW_CONTACT",
  INTERNAL = "ZEDWORKS_SOFTWARE_INTERNAL"
}

export interface EmailMsg {
  to?: string;
  from?: string;
  subject: string;
  text?: string;
  html?: string;
}

@Injectable()
export class MailService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' });
  private url = 'https://zedworkssoftware.com/mail/contact.php';

  constructor(private http: HttpClient) { }

  sendEmailPhp(data, mailType = "1"): Observable<any> {
    //Observable<Response> ob:Observable<any> = this.http.post("localhost:3000/sendmail", {});

    let body = new URLSearchParams();
    body.set('mailType', mailType);
    for (let prop in data) {
      body.set(prop, data[prop]);
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    //console.log(body.toString());

    //let options = new RequestOptions({ headers: headers, params: body });
    return this.http.post(this.url, body.toString(), {
      headers: headers
    }); //.map(res => res.json());

    //return this.http.post(this.url, body.toString(),{
    //  headers : this.headers})
    //  //.map(response => response.json());

    // return this.http.post('localhost:3000/sendmail', formData);
    //.map((res:Response) => res.json());
    //.catch((error:any) => Observable.throw(error.json().error || 'Server error')); //...errors if any

    //$http({
    //  method : 'POST',
    //  url : 'resources/curl.php',
    //  data: postData,
    //  headers : {'Content-Type': 'application/x-www-form-urlencoded'}
    //
    //})
  }

  sendEmail(data: any, mailType: EmailType): Promise<any> {
    let emailTemplate = "";
    for (let key in data) {
      emailTemplate += `<p><b>${key}:</b> ${data[key]}</p>`;
    }

    const msg: EmailMsg = {
      subject: mailType.toString(),
      html: emailTemplate
      // text: 'text msg'
    };

    return FirebaseFunctionUtils.executeHttpFunc('sendEmail', { msg });
  }
}
