import { Directive, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[zwDropdownClick]'
})
export class ZwDropdownClickDirective {
  @HostBinding('class.open') isOpen: boolean = false;

  @HostListener('click') toggleOpen(){
    this.isOpen = !this.isOpen;
  }

  constructor() { }

}

