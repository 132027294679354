import * as firebase from 'firebase/app'; // Firebase App is always required and must be first

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

// const settings = { timestampsInSnapshots: true };
const config = {
    apiKey: "AIzaSyBXoJE1kK6A54MS97X5Dbpfw1XVhX3N6Ew",
    authDomain: "zedworks-software.firebaseapp.com",
    databaseURL: "https://zedworks-software.firebaseio.com",
    projectId: "zedworks-software",
    storageBucket: "zedworks-software.appspot.com",
    messagingSenderId: "467018538942",
    appId: "1:467018538942:web:f7563318fb630ef4"
};

firebase.initializeApp(config);
// firebase.firestore().settings(settings);

export default firebase;
