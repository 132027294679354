// import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalErrorHandler } from './error-handler/error.handler';
//import { ZwDropdownClickDirective } from '../../node_modules/zw-common/src/app/app.module';
// import { ZwDropdownClickDirective } from './common/directives/zw-dropdown-click.directive';
// import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ReactiveService } from './services/reactive.service';
import { MailService } from './services/mail.service';
import { ImageTransformService } from './components/image-transform/image.transform.service';
import { AppComponent } from './app.component';
import { AppCommonModule } from './app.common.module';
import { appRoutes } from './app-routes';
import { SiteModule } from './site/site.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // BrowserModule,
    BrowserAnimationsModule,
    // CommonModule,
    // // ToastrModule.forRoot({ timeOut: 15000, closeButton: true, positionClass: "toast-bottom-full-width" }), // toast-top-right toast-bottom-full-width ToastrModule added
    // FormsModule,
    // ReactiveFormsModule,
    // HttpClientModule,
    AppCommonModule,
    SiteModule,
    //NgbModule.forRoot(),
    //ZWCommonModule,
    RouterModule.forRoot(appRoutes) // <router-outlet>
    //RouterModule.forChild(appRoutes) // for lazy loading
  ],
  providers: [
    MessageService,
    ReactiveService,
    MailService,
    ImageTransformService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
