import { Component, OnInit, Input } from '@angular/core';
import { ReactiveService } from '../../../services/reactive.service';

@Component({
  selector: 'zs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Input() config: {isVhSupported: boolean};
  // @Input() loading: boolean;
  homeHeight:string = "100vh";

  constructor(private reactiveService:ReactiveService) { }

  ngOnInit() {
    if (!this.config.isVhSupported){
      this.adjustHeight();
      this.reactiveService.onResize.subscribe(()=> {
        this.adjustHeight();
      });
    }
  }

  // fallback for older browsers
  adjustHeight(){
    this.homeHeight = window.innerHeight + "px";
  }

  scrollTo(anchor:string){
    this.reactiveService.scrollTo(anchor);
  }

}
