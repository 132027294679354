import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { TransformImage } from './image.transform';
import { ImageTransformService } from './image.transform.service';
import { ReactiveService } from '../../services/reactive.service';

@Component({
  selector: 'zs-image-transform',
  templateUrl: './image.transform.component.html',
  styleUrls: ['./image.transform.component.scss']
})
/*
  Component is also the controller for the main images and transitions between them.
 */
export class ImageTransformComponent implements OnInit {
  //[class.isLoading]="loading"
  @Input() config:{isTopLayer: boolean, images: TransformImage[], standardImage: boolean};
  public scrollDown: boolean;
  public isMobile: boolean;

  private currentImageIndex:number = 0;
  public transitionImages: TransformImage[] = [];

  constructor(private imageTransformService:ImageTransformService, private reactiveService:ReactiveService) {
    this.transitionImages = this.imageTransformService.getImages();
  }

  trackImageByFn(index, image) {
    return index;
  }

  runNextTransition(){
    let nextIndex = this.currentImageIndex + 1;
    if (nextIndex >= this.transitionImages.length){ // loop to beginning
      nextIndex = 0;
    }

    setTimeout(() => {
      this.switchImages(nextIndex);
      this.runNextTransition();
    }, TransformImage.transitionDuration); // this.transitionImages[this.currentImageIndex].transitionDuration
  }

  switchImages(nextIndex:number){
    let image = this.transitionImages[this.currentImageIndex];
    let nextImage  = this.transitionImages[nextIndex];
    nextImage.startAnimation(); // get next going
    nextImage.fadeIn();
    image.fadeOut();
    this.currentImageIndex = nextIndex;
    image.stopAnimation();
  }

  ngOnInit() {
    if (this.config.images && this.config.images.length > 0){
      this.transitionImages = this.config.images;
      this.start();
    } else {
      this.imageTransformService.imagesLoaded().then(()=>{
        this.start();
      });
    }

    this.isMobile = this.reactiveService.isMobile();
    if (!this.isMobile){
      this.reactiveService.onScrolledDown.subscribe((o)=>{
        this.scrollDown = o.val > 0;
      });
    }
  }

  start(){
    if (this.config.isTopLayer || !this.reactiveService.isModernBrowser()){
      this.transitionImages[0].opacity = 1;
      return;
    }

    // get first image going with fade in
    let firstImage = this.transitionImages[0];
    firstImage.startAnimation();
    if (this.transitionImages.length == 1){
      firstImage.opacity = 1;
    } else {
      firstImage.fadeIn();
      this.runNextTransition();
    }
  }

}
