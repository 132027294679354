import { Component, OnInit, Input, Inject } from '@angular/core';
// import { Router } from "@angular/router";
import { ReactiveService } from '../../services/reactive.service';
import { TruncatePipe } from '../../components/pipes/truncate.pipe';
import * as _ from 'lodash';

@Component({
  selector: 'zs-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [TruncatePipe]
})
export class NavComponent implements OnInit {
  @Input() config: { isMobileDevice: boolean, hideLinks?: boolean };
  public scrollDown: boolean = false;
  public navbarCollapsed: boolean = true;
  private isAbbrScreenWidth: boolean = false; // width to truncate text
  private documentHeight: number;

  public navLinks: { title: string, link: string, isSelected: boolean, abbr: boolean, start: number, end: number, color: boolean }[] = [
    { title: "Home", link: "#", isSelected: true, abbr: false, start: 0, end: 0, color: false },
    { title: "About", link: "#about", isSelected: false, abbr: false, start: 0, end: 0, color: false },
    { title: "Services", link: "#services", isSelected: false, abbr: false, start: 0, end: 0, color: false },
    { title: "Technologies", link: "#technologies", isSelected: false, abbr: true, start: 0, end: 0, color: false },
    { title: "Contact", link: "#contact", isSelected: false, abbr: false, start: 0, end: 0, color: false }
  ];

  constructor(private reactiveService: ReactiveService) { }

  ngOnInit() {
    //this.reactiveService.onScrollToLink.subscribe((activeLink)=> {
    //  _.forEach(this.navLinks, function(link) {
    //    link.isSelected = false;
    //  });
    //  let selected = _.find(this.navLinks, ['link', activeLink]);
    //  selected.isSelected = true;
    //  this.navbarCollapsed = true; // close mobile menu
    //});

    if (this.config.hideLinks) {
      return;
    }

    this.reactiveService.onScrolledDown.subscribe((o) => {
      this.scrollDown = o.val > 0;
      if (!o.isScrolling) this.setSelected(o.val, true);
    });
    this.reactiveService.onResize.subscribe(() => {
      this.calculateSectionPositions();
    });

    this.calculateSectionPositions();

    // if there's a hash link, not home
    if (window.location.hash && window.location.hash != this.navLinks[0].link) {
      let selected = _.find(this.navLinks, ['link', window.location.hash]);
      if (selected) {
        selected.isSelected = true;
        this.navLinks[0].isSelected = false; // deselect home
        //this.reactiveService.scrollTo(selected.start);
      }
    }

    // this.isAbbrScreenWidth = window.innerWidth <= 920 && window.innerWidth > 795; // safari
    //this.isAbbrScreenWidth = window.innerWidth <= 882 && window.innerWidth > 805;
    this.isAbbrScreenWidth = window.innerWidth <= 993 && window.innerWidth > 805;
    this.reactiveService.onResize.subscribe(() => {
      this.isAbbrScreenWidth = window.innerWidth <= 993 && window.innerWidth > 805;
    });
  }

  trackNavByFn(index, navLink) {
    return index;
  }

  scrollTo(navLink: any) {
    this.navLinks.forEach(n => { n.isSelected = false; });
    navLink.isSelected = true;
    const el = document.getElementById(navLink.link.substr(1));
    if (el && el.scrollIntoView) {
      el.scrollIntoView();
    } else {
      window.location.href = navLink.link;
    }
    this.navbarCollapsed = true;
  }

  //scroll(el:Element, navLink:any){
  //  this.scrollService.navLinks.forEach(n=>{n.isSelected = false;});
  //  navLink.isSelected = true;
  //  //this.scrollService.scrollTo(navLink, el);
  //  this.navbarCollapsed = true;
  //  return false;
  //}

  calculateSectionPositions() {
    // set start and end for each section in the window
    this.documentHeight = this.getDocumentHeight();
    this.navLinks[0].end = this.getViewportHeight() + ((!this.config.isMobileDevice) ? 60 : -26); // window.innerHeight  + ((!this.isMobileDevice)?130:50); // ((this.isMobileDevice)?-26:44); set home height
    for (let i = 1; i < this.navLinks.length; i++) {
      let anchor = window.document.getElementById(this.navLinks[i].link.substring(1));
      let start = this.navLinks[i - 1].end;
      let end = 56 + start + this.getFullHeight(this.getNextElementSibling(anchor));
      this.navLinks[i].start = start;
      this.navLinks[i].end = end;
    }
  }

  setSelected(scrollPostion: number, scrolling: boolean = false) {
    let selected = this.search(scrollPostion, 2); //_.find(this.navLinks, function(link){ return link.start <= scrollPostion && scrollPostion < link.end});
    if (selected && !selected.isSelected) {
      _.forEach(this.navLinks, function (link) {
        link.isSelected = false;
      });
      selected.isSelected = true;
      if (scrolling) {
        selected.color = true;
        setTimeout(() => {
          selected.color = false;
        }, 700)
      }
      this.reactiveService.onSelectedNav.emit(selected);
    }
  }

  // TODO
  search(value: number, index: number): any {
    let found = this.isMatch(this.navLinks[index], value);
    if (found) return this.navLinks[index];
    if (value > this.navLinks[index].end) {
      if (index == this.navLinks.length - 1) return this.navLinks[index]; // edge case - footer
      return this.search(value, index + 1);
    } else {
      return this.search(value, index - 1);
    }
  }

  isMatch(navLink: any, value: number) {
    return navLink.start <= value && value <= navLink.end;
  }

  toggleMenu(e) {
    if (this.navbarCollapsed) { // opening menu, adjust selected if needed
      this.setSelected(document.documentElement.scrollTop || document.body.scrollTop);
    }
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  getNextElementSibling(element) {
    if (element.nextElementSibling) {
      return element.nextElementSibling;
    } else {
      return element.nextSibling;
    }
  }

  getFullHeight(elm) {
    var elmHeight, elmMargin;
    if (document.all) {// IE
      elmHeight = elm.currentStyle.height;
      elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10) + "px";
    } else {// Mozilla
      elmHeight = document.defaultView.getComputedStyle(elm, '').getPropertyValue('height');
      elmMargin = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top')) + parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom')) + "px";
    }
    return (parseInt(elmHeight.substr(0, elmHeight.length - 2)) + parseInt(elmMargin.substr(0, elmMargin.length - 2)));
  }

  getEndLocation(anchor) {
    //let location = 0;
    //if (anchor.offsetParent) {
    //  do {
    //    location += anchor.offsetTop;
    //    anchor = anchor.offsetParent;
    //  } while (anchor);
    //}
    //location =  Math.max(location, 0); // - headerHeight - offset
    //debugger
    //return Math.min(location, this.getDocumentHeight() - this.getViewportHeight());
    return this.getDocumentHeight() - this.getViewportHeight();
  }

  getDocumentHeight() {
    return parseInt(window.getComputedStyle(document.documentElement).height, 10);
  }

  getViewportHeight() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }

}
