import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'zs-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['../cube.scss', './z.styles.scss', './logo.styles.scss', './footer.logo.styles.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
