import { ErrorHandler, Injectable } from '@angular/core';
import { ReactiveService } from '../services/reactive.service';
import { MailService, EmailType } from '../services/mail.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private reactiveService: ReactiveService, private mailService: MailService) { }
  handleError(error) {
    this.notifyAdmin(error);
    console.log(error.stack);
    throw error; // Rethrow the error otherwise it gets swallowed
  }

  notifyAdmin(error: Error) {
    if (window.location.origin === "http://localhost:4200") return;
    let data = {
      errorStack: error.stack,
      browser: navigator.userAgent,
      screenWidth: this.reactiveService.getScreenWidth(),
      screenHeight: this.reactiveService.getScreenHeight(),
      hasStorage: typeof (Storage)
    }

    this.mailService.sendEmail(data, EmailType.INTERNAL)
      .then(
        data => {
        },
        error => {
        });
  }

}
