import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MailService, EmailType } from '../../../services/mail.service';
import { ReactiveService } from '../../../services/reactive.service';
// import { ToastrService } from 'tr';
// import { ToastrConfig } from 'ngx-toastr';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'zs-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  emailPattern: string = "[a-zA-Z0-9._]+[@]+[a-zA-Z0-9]+[.]+[a-zA-Z]{2,6}";
  contactForm: FormGroup;
  post: any;
  isBtnDisabled: boolean = false;

  //@Input() set categoryId(value: string) {
  //
  //  this._categoryId = value;
  //  this.doSomething(this._categoryId);
  //
  //}

  constructor(private formBuilder: FormBuilder, private mailService: MailService, private reactiveService: ReactiveService,
    // public toastrService: ToastrService, 
    private messageService: MessageService,
    vcr: ViewContainerRef) {
    this.contactForm = formBuilder.group({
      'name': [null, Validators.required],
      'company': [null],
      'phone': [null, Validators.compose([Validators.required, Validators.minLength(7)])],
      'email': [null, Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      'message': [null, Validators.compose([Validators.maxLength(2500)])]
    });

    //this.toastr.setRootViewContainerRef(vcr);
    //this.toastrConfig.timeOut = 15000;
    //this.toastrConfig.

    // navigating away clears the red errors
    this.reactiveService.onSelectedNav.subscribe((selected) => {
      if (selected.title != "Contact") {
        this.validateAllFormFields(this.contactForm, false);
      }
    });
  }

  ngOnInit() {
  }

  showSuccess(name?:string) {
    const msg: string = name + ", our team will be in touch with you within the next 48 hours.";
    this.messageService.add({ key: 'default', severity: 'success', summary: 'Inquiry Received', detail: msg, life: 10000 });
  }

  showError() {
    const msg: string = "Please give us a call or email us at contact@zedworkssoftware.com";
    this.messageService.add({ key: 'default', severity: 'error', summary: 'Error', detail: msg, life: 6000 });
  }

  validateAllFormFields(formGroup: FormGroup = this.contactForm, validate: boolean = true) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        if (validate) {
          control.markAsTouched({ onlySelf: true });
        } else {
          control.markAsUntouched({ onlySelf: true });
        }
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  //[disabled]="!contactForm.valid && !this.reactiveService.isMobile()"
  onSubmit(formData) {
    this.isBtnDisabled = true;
    if (!this.contactForm.valid) {
      this.messageService.add({ key: 'default', severity: 'warn', summary: 'Attention', detail: 'Please fill out the missing form fields.', life: 5000 });
      // markAsTouched
      this.validateAllFormFields();
      this.isBtnDisabled = false;
      return;
    };

    this.mailService.sendEmail(formData, EmailType.CONTACT)
      .then(
        data => {
          console.debug('contact response data:', data);
          // if (data == "1" || data == 1) {
          this.showSuccess(formData.name);
          this.contactForm.reset();
          this.isBtnDisabled = false;
          // } else {
          //   this.showError();
          //   this.isBtnDisabled = false;
          // }
        },
        error => {
          this.showError();
          //console.log(error);
          // this.loading = false; // TODO
          this.isBtnDisabled = false;
        });


    //this.mailService.sendEmail(formData)
    //  .subscribe((res)=>{
    //  this.showSuccess();
    //    this.contactForm.reset();
    //},
    //err=> {
    //  //alert('An error occured. Please contact us at contact@zedworkssoftware.com')
    //  this.showError();
    //});

    // .subscribe((res:Person) => { this.postResponse = res; console.log(res); });

  }
}
