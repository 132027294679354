import { Routes, RouterModule } from '@angular/router';

export const appRoutes: Routes = [
    {
        path: 'schedule',
        loadChildren: () => import('./scheduling/scheduling.module').then(mod => mod.SchedulingModule)
    },
    {
        path: '',
        loadChildren: () => import('./site/site.module').then(mod => mod.SiteModule)
    },
    { // prob not necessary 
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    }
];