import { EventEmitter, HostListener, ElementRef, Injectable } from '@angular/core';
//import { BehaviorSubject } from 'rxjs/Rx';
import { ReactiveService } from '../../services/reactive.service';
import { TransformImage } from './image.transform';

@Injectable()
export class ImageTransformService {
  //public imagesLoadComplete: BehaviorSubject<boolean> = BehaviorSubject.create();
  private prefixPath:string = "";
  private imagesConfigArr = [
    {name:"508327098.jpg", cssClass:"axis"},
    {name:"507804714.jpg", cssClass:"move-down"}
  ];
  private transitionImages: TransformImage[] = [
    // 539954410 big empty space to the left. move in
    // 507804714 scale in - looks like going down
    // 539954238 scals in - going down
    // 534109952 tunnel effect
    // 508028612 tunnel effect - similar to 508248420

    //new TransformImage(this.getPath() + "508327098.jpg", "axis"), // 50 40
    //new TransformImage(this.getPath() + "507804714.jpg", "move-down") //  539954238

    //new RotateXTransform("color-adjust/508248420color.jpg", "50%", "50%", 0, "rotate-x")
  ];
  private cachedImagesCallbacks = [];

  constructor(private reactiveService:ReactiveService){
    this.init();
  }

  private setImages(...imgArgs: string[]){
    this.imagesConfigArr.forEach((img, i)=> {
      let cssArgs = "";
      if (imgArgs.length > i && imgArgs[i]) cssArgs = imgArgs[i];
      this.transitionImages.push(
        new TransformImage(this.prefixPath + img.name, img.cssClass + cssArgs)
      )
    });
  }

  private cacheImages(){
    this.getImages().forEach((img)=> {
      var image = new Image();
      image.onload = function(){
        // console.log(image.src + " loaded");
        return new Promise((resolve) => { resolve(img);});
      }
      this.cachedImagesCallbacks.push(image.onload);
      image.src = img.imageSrc;
    })
  }

  getImages(){
    return this.transitionImages;
  }

  init(){
    let largestResolution = Math.max(this.reactiveService.getScreenWidth(), this.reactiveService.getScreenHeight());
    // TODO: add xl
    //if (largestResolution >= 1600){
    //  // up to 1920 need 2448
    //  this.prefixPath = "l/";
    //} else
    if (largestResolution <= 1200){
      if (largestResolution <= 660) {
        this.prefixPath = "s/";
      } else {
        this.prefixPath = "m/";
      }
    }
    this.setImages();
    this.cacheImages();
  }

  imagesLoaded(){
    return Promise.all(this.cachedImagesCallbacks).then(()=>{
      // console.log("all images loaded");
    });
  }
}
