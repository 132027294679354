import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, doTruncate: boolean): any {
    if (doTruncate) return value.substr(0,4);
    return value;
  }

}
