import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';

import { NavComponent } from './components/nav/nav.component';
import { ImageTransformComponent } from './components/image-transform/image.transform.component';
import { LogoComponent } from './components/cube/logo/logo.component';
import { LoaderComponent } from './components/cube/loader/loader.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { Text3DComponent } from './components/text-3D/text.3D.component';
import { TruncatePipe } from './components/pipes/truncate.pipe';
import { TitleComponent } from './components/title/title.component';
import { ZwDropdownClickDirective } from './common/directives/zw-dropdown-click.directive';

// app common imports and declarations
@NgModule({
  imports: [
    CommonModule,
    // BrowserModule,
    // BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastModule
  ],
  declarations: [
    NavComponent,
    ImageTransformComponent,
    LogoComponent,
    LoaderComponent,
    FooterComponent,
    Text3DComponent,
    TruncatePipe,
    TitleComponent,

    ZwDropdownClickDirective
  ],
  providers: [

  ],
  exports: [
    NavComponent,
    ImageTransformComponent,
    LogoComponent,
    LoaderComponent,
    FooterComponent,
    Text3DComponent,
    TruncatePipe,
    TitleComponent,

    ZwDropdownClickDirective,

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule
  ]
})

export class AppCommonModule { }