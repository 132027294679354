

export class Layer {
  public text:string;
  public zIndex:number;
  public top:number;
  public left:number;
  public isDeep:boolean;
  public r:number; // rgb values
  public g:number;
  public b: number;

  constructor(text: string, zIndex: number, top:number, left:number, r:number = 255, g:number = 255, b:number = 255, isDeep:boolean = false){
    this.text = text;
    this.zIndex = zIndex;
    this.top = top;
    this.left = left;
    this.r = r;
    this.g = g;
    this.b = b;
    this.isDeep = isDeep;
  }

  lightenRgb(){
    return this.rgb(this.r, this.g + 10, this.b + 10);
  }

  rgb(r:number=this.r, g:number =this.g, b:number = this.b){
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
}
