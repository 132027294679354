import { EventEmitter, HostListener, ElementRef, Injectable } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import smoothscroll from 'smoothscroll-polyfill';

@Injectable()
export class ReactiveService {
  public onResize: EventEmitter<null> = new EventEmitter<null>();
  public onSelectedNav: EventEmitter<any> = new EventEmitter<any>();
  //public onScrollToLink: EventEmitter<string> = new EventEmitter<string>();
  //public homePageScroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  public onScrolledDown: EventEmitter<{val:number, isScrolling:boolean}> = new EventEmitter<{val:number, isScrolling:boolean}>();
  private isScrollingTo:boolean = false;
  private _isMobile:boolean = this.setIsMobile();
  private isFirefox:boolean = false;
  public isSafari:boolean = false;
  private _isModernBrowser:boolean = true;
  private _screenWidth:number = this.setScreenWidth();
  private _screenHeight:number = this.setScreenHeight();

  init(){
    this.browserDetection();
    if (this._isModernBrowser){
      /*
       http://iamdustan.com/smoothscroll/
       https://github.com/iamdustan/smoothscroll
       */
      smoothscroll.polyfill();
    }
  }

  isMobile():boolean{
    return this._isMobile;
  }

  isModernBrowser():boolean {
    return this._isModernBrowser;
  }

  getScreenWidth():number {
    return this._screenWidth;
  }

  getScreenHeight():number {
    return this._screenHeight;
  }

  private setIsMobile():boolean {
    ///<summary>Detecting whether the browser is a mobile browser or desktop browser</summary>
    ///<returns>A boolean value indicating whether the browser is a mobile browser or not</returns>

    if (sessionStorage.desktop) // desktop storage
      return false;
    else if (localStorage.mobile) // mobile storage
      return true;

    // alternative
    var mobile = ['iphone','ipad','android','blackberry','nokia','opera mini','windows mobile','windows phone','iemobile'];
    for (var i in mobile) if (navigator.userAgent.toLowerCase().indexOf(mobile[i].toLowerCase()) > 0) return true;

    // nothing found.. assume desktop
    return false;
  }

  private browserDetection(){
    let browser:string = navigator.userAgent.toLowerCase();
    this.isFirefox = browser.indexOf('firefox') > -1;
    this.isSafari = browser.indexOf('safari') != -1 && browser.indexOf('chrome') == -1;
    // typeof(Intl)
    this._isModernBrowser = !!document.querySelector && !!window.localStorage && !!window.addEventListener && !this.iOldPhone();
  }

  private iOldPhone():boolean{
    // iPhone 4/4S
    if ((window.screen.height / window.screen.width == 1.5) && (window.devicePixelRatio == 2)) {
      return true; //"iPhone 4 or 4S";
    }
    // iPhone 1/3G/3GS
    if ((window.screen.height / window.screen.width == 1.5) && (window.devicePixelRatio == 1)) {
      return true; //"iPhone 1, 3G or 3GS";
    }

    return false;
  }

  //outerWidth, outerHeight: the real pixel real estate
  //innerWidth, innerHeight the virtual pixel real estate

  private setScreenWidth():number {
    return window.innerWidth || document.body.clientWidth || window.screen.availWidth || window.screen.width;
  }

  private setScreenHeight():number {
    return window.innerHeight || document.body.clientHeight || window.screen.availHeight || window.screen.height;
  }

  cssPropertyValueSupported(prop, value):boolean {
    var d = document.createElement('div');
    d.style[prop] = value;
    return d.style[prop] === value;
  }

  scrolled(number:number){
    this.onScrolledDown.emit({val:number, isScrolling:this.isScrollingTo});
  }

  //attachScroll(){
  //  var t:any = this;
  //  this.smoothScroll = new SmoothScroll('[data-scroll*="#"],a[href*="#"]', {
  //    speed: 1000,
  //    offset: t.isFirefox? 35: t._isMobile? 75: 55, // -1px from nav heights
  //    easing: 'easeInOutQuint',
  //    before: function (targetLink, originLink) {
  //      t.isScrollingTo = true;
  //      t.onScrollToLink.emit(originLink.getAttribute("data-link") || originLink.hash);
  //    },
  //    after: function (){
  //      t.isScrollingTo = false;
  //    }
  //  });
  //  // ipad - bind tap event
  //  // click touchstart
  //  // on/touchstart click
  //
  //  // if ipad attach event to buttons
  //}

  public scrollTo(anchor:string){
    // if native smooth scrolling supported
    if (document.documentElement && document.documentElement.style['scrollBehavior']){
      window.location.href = '#' + anchor;
      return;
    }

    // Scroll to a certain element
    document.querySelector('#' + anchor).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  //onBeforeScroll(originLink){
  //  this.isScrollingTo = true;
  //  this.onScrollToLink.emit(originLink.getAttribute("data-link") || originLink.hash);
  //}
  //
  //onAfterScroll(){
  //  this.isScrollingTo = false;
  //}

  // native browser scrolling behavior:
  //window.scrollBy({
  //  "behavior": "smooth",
  //  "left": left,
  //  "top": top
  //  });
  //window.scrollTo({
  //  "behavior": "smooth",
  //  "left": left,
  //  "top": top
  //  });
  //You can also use it in scrollIntoViewOptions for Element.scrollIntoView()

  //scrollTo(value:number){
  //  var t = this;
  //  let scroll = new SmoothScroll({
  //    offset: t.isMobile? 80: 55,
  //  });
  //
  //  setTimeout(()=>{
  //    //scroll.animateScroll( value );
  //  },1000)
  //}

  //var scrollLeft = (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft;
  //var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  // visible equivalent: (element.offsetWidth > 0 || element.offsetHeight > 0)
  //  constructor(private element: ElementRef, private renderer: Renderer){

  //@HostListener('window:resize')
  //ngDoCheck() {
  //  this.isInView = this.element.nativeElement.offsetParent !== null;
  //}

}
