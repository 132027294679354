import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import { ReactiveService } from '../../../services/reactive.service';

@Component({
  selector: 'zs-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private reactiveService:ReactiveService) {

  }

  ngOnInit() {
    //this.reactiveService.onSelectedNav.subscribe((selected)=>{
    //  if (selected.title == "About"){
    //    console.log("animate About")
    //  }
    //});
  }
}
