import { Component, OnInit, Input } from '@angular/core';
//import { ReactiveService } from '../react/reactive.service';

@Component({
  selector: 'zs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  //isHomePage: boolean = true;

  //constructor(private reactiveService:ReactiveService) { }
  constructor() { }

  ngOnInit() {
    //this.reactiveService.homePageScroll.subscribe((val)=>{
    //  if (val != this.isHomePage) this.isHomePage = val;
    //});
  }

  getPositionStyles(){
    let styles = {};
    //if (this.isHomePage){
    //  // top
    //  // no bottom - no need for definitions
    //  styles['top'] = window.innerHeight - 40 + 'px';
    //} else {
    //  // bottom
    //  // no top - no need for definitions
    //  styles['bottom'] = 0;
    //}

    // TODO:
    return styles;
  }

}
