import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
//import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Layer } from './layer';

@Component({
  selector: 'zs-3D-text',
  templateUrl: './text.3D.component.html',
  styleUrls: ['./text.3D.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Text3DComponent implements OnInit {
  @Input() config: { text: string, rgb: number[], depth: number }; // make immutable

  public layers: Layer[] = [];
  //private sanitizer:DomSanitizer;

  constructor() { // sanitizer: DomSanitizer
    //this.sanitizer = sanitizer;
  }

  //getStyle(value):SafeStyle{
  //  //return this.sanitizer.bypassSecurityTrustStyle("z-index:" + layer.zIndex + "; top:" + layer.top + "; left:" + layer.left);
  //  return this.sanitizer.bypassSecurityTrustStyle(value + 'px');
  //}

  ngOnInit() {
    let zIndex = 1000;
    let top = 0;
    let left = 0;
    let isDeepLayer = false;
    let r = this.config.rgb[0];
    let g = this.config.rgb[1];
    let b = this.config.rgb[2];

    for (let i = 0; i < this.config.depth; i++) {
      this.layers.push(new Layer(this.config.text, zIndex, top, left, r, g, b, isDeepLayer));
      top++;
      left++;
      zIndex--;
      g = g - 8;
      b = b - 8;
      if (i >= 4) isDeepLayer = true;
    }
  }

  trackLayerByFn(index, layer) {
    return index;
  }

}
