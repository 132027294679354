import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SiteRoutingModule } from './site-routing.module';
import { MainComponent } from './main/main.component';
import { AppCommonModule } from 'app/app.common.module';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { TechnologiesComponent } from './pages/technologies/technologies.component';
import { ContactComponent } from './pages/contact/contact.component';

@NgModule({
  declarations: [
    // FormsModule,
    // ReactiveFormsModule,
    MainComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    TechnologiesComponent,
    ContactComponent
  ],
  imports: [
    CommonModule,
    SiteRoutingModule,
    AppCommonModule
  ],
  providers: [

  ]
})
export class SiteModule { }
