import { Component, ViewEncapsulation } from '@angular/core';

// import * as functions from 'firebase-functions'

//var firebase = require("firebase/app");
//require("firebase/auth");
//require("firebase/database");
//import * as firebase from "nativescript-plugin-firebase";
// import firebase = require("nativescript-plugin-firebase");

//var firebase = require('firebase/app');
//require('firebase/database');

//var config = {
//  apiKey: "AIzaSyA9gUmSBu4SZ4P9H_4lXuN1ouD_GBKq3aw",
//  authDomain: "burn-56840.firebaseapp.com",
//  databaseURL: "https://burn-56840.firebaseio.com",
//  storageBucket: "burn-56840.appspot.com"
//};
//firebase.initializeApp(config);

// dependenies - "firebase": "^3.0.1",

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {

}
