
export class TransformImage {
  private static baseUrl:string = "/assets/images/transition/";
  private static fadeDuration:number = 1000;
  public static transitionDuration:number = 11000;
  imageSrc:string;
  cssClass: string;
  public opacity:number = 0;
  public fadeInActive:boolean = false;
  public fadeOutActive:boolean = false;
  public isAnimationActive:boolean = false;
  public reverse:boolean = false;

  constructor(imageSrc: string, cssClass: string=""){
    this.imageSrc = TransformImage.baseUrl + imageSrc;
    this.cssClass = cssClass;
  }

  fadeIn(){
    this.fadeInActive = true;
    setTimeout(()=> {
      this.opacity = 1;
    }, TransformImage.fadeDuration);
  }

  fadeOut(){
    this.fadeOutActive = true;
    setTimeout(()=> {
      this.opacity = 0;
    }, TransformImage.fadeDuration);
  }

  startAnimation(){
    this.isAnimationActive = true;
  }

  stopAnimation(){
    setTimeout(()=> {
      this.isAnimationActive = false;
      this.fadeOutActive = false;
      this.fadeInActive = false;
      this.reverse = !this.reverse;
    }, TransformImage.fadeDuration);
  }
}
