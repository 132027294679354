import { Component, OnInit, Input } from '@angular/core';
import { ReactiveService } from '../../services/reactive.service';

@Component({
  selector: 'zs-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() config: {text: string, left: number, adjustLeft:boolean};
  private initLeft:number;

  constructor(private reactiveService:ReactiveService) { }

  ngOnInit() {
    if (this.config.adjustLeft){
      this.initLeft = this.config.left;
      this.adjustLeftWidth();
      this.reactiveService.onResize.subscribe(()=>{
        this.adjustLeftWidth();
      });
    }
  }

  adjustLeftWidth(){
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth < 427){
      this.config.left = 142;
    } else {
      this.config.left = this.initLeft;
    }
  }
}
